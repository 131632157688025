
import { defineComponent } from "vue";
import NavigatorMobile from "@/components/common/nav/Mobile.vue";
import NavigatorDesktop from "@/components/common/nav/Desktop.vue";
import { NavState, ScreenType } from "@/models/common";
import { ResponsiveManager } from "@/controllers/common";

export default defineComponent({
  name: "NavigatorCommon",
  components: {
    NavigatorMobile,
    NavigatorDesktop,
  },
  data() {
    return {
      isMenuOpen: false,
    };
  },
  watch: {
    // eslint-disable-next-line
    $route(to, from) {
      this.isMenuOpen = false;
    },
    showMobile: function () {
      if (!this.showMobile) {
        this.isMenuOpen = false;
      }
    },
  },
  computed: {
    showMobile(): boolean {
      return (
        this.state.device === ScreenType.mobile ||
        this.state.device === ScreenType.tablet
      );
    },
    deviceType(): ScreenType {
      return ResponsiveManager.retrieveScreenSize();
    },
    state(): NavState {
      return {
        opened: this.isMenuOpen,
        device: this.deviceType,
      };
    },
  },
});
