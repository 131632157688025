import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import "firebase/analytics";
import "firebase/auth";

export default class Firebase {
  static readonly firebaseConfig = {
    apiKey: "AIzaSyA1pk_BtgccORH29P9aKRUluGeiXmZHrWE",
    authDomain: "website-2021cc.firebaseapp.com",
    projectId: "website-2021cc",
    storageBucket: "website-2021cc.appspot.com",
    messagingSenderId: "204887120802",
    appId: "1:204887120802:web:f08ad4c354558a7daeb0fb",
    measurementId: "G-134K9241HH",
  };

  private static app: FirebaseApp;
  private static analytics: Analytics;

  static initialize(): void {
    Firebase.app = initializeApp(this.firebaseConfig);
    Firebase.analytics = getAnalytics(Firebase.app);
  }

  static logEvent(eventName: string): void {
    logEvent(Firebase.analytics, eventName);
  }
}
