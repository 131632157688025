
import { defineComponent } from "vue";
import { useMeta } from "vue-meta";
import NavigatorCommon from "@/components/common/nav/Container.vue";
import Footer from "@/components/common/Footer.vue";
import { ResponsiveManager, Store } from "./controllers/common";
import Firebase from "./controllers/firebase";

export default defineComponent({
  components: {
    NavigatorCommon,
    Footer,
  },
  setup() {
    useMeta({
      title: "",
      description:
        "Somos un Centro de Adoración a Dios, un Centro de Revelación de la gloria de Dios, un Centro de Demostración del Poder de Dios y un Centro de Expansión del Reino de Dios.",
      htmlAttrs: { lang: "es", amp: true },
    });
    Firebase.initialize();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      Store.update("changeDevice", ResponsiveManager.screenSize());
    },
  },
});
