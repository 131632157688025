
import { NavState } from "@/models/common";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "NavigatorMobile",
  props: {
    modelValue: {
      type: Boolean,
    },
    state: {
      type: Object as PropType<NavState>,
      required: true,
    },
  },
  emits: ["update:modelValue", "login"],
  computed: {
    isMenuOpen: {
      get(): boolean {
        return this.modelValue;
      },
      set(value: boolean): void {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
